import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import Viewport from '@vaersaagod/tools/Viewport';
import gsap from 'gsap';

import { loadMatter } from '../../../lib/async-bundles';
import { COMPONENT_INIT, VIEWPORT_HEIGHT_CHANGE } from '../../../lib/events';
import { dispatchEvent } from '../../../lib/helpers';

require('intersection-observer');

export default (el, { textures }) => {

    const $el = $(el);

    const canvasContainer = $el.find('[data-canvas]').get(0);

    let Matter;

    let engine;
    let world;
    let render;
    let runner;
    let mouse;
    let mouseConstraint;
    let bodies;

    let observer;

    let texturesAreLoaded = false;
    let matterHasInited = false;
    let hasInteracted = false;

    const originalPositions = {};
    let cachedPositions = {};

    let { width: stageW } = Viewport;
    let stageH = $el.height();

    const getLogoSvg = () => $el.find('[data-logo]').get().filter(node => $(node).css('display') !== 'none')[0].querySelector('svg');

    const onTouchStartCheck = e => {
        let { position } = render.mouse.position;

        if (e.touches && e.touches.length > 0) {
            position = { x: e.touches[0].pageX, y: e.touches[0].pageY };
        }

        if (Matter.Query.point(world.bodies, position).length > 0) {
            render.mouse.mousedown(e);
            render.mouse.element.addEventListener('touchmove', render.mouse.mousemove);
        }
    };

    const onTouchEndCheck = e => {
        render.mouse.mouseup(e);
        render.mouse.element.removeEventListener('touchmove', render.mouse.mousemove);
    };

    const onAfterTick = () => {
        if (!hasInteracted || !bodies.length) {
            return;
        }
        bodies.forEach(body => {
            const { position, angle, label } = body;
            if (position.x !== originalPositions[label].x && position.y !== originalPositions[label].y) {
                cachedPositions[label] = { ...position, angle };
            }
        });
    };

    const destroyEngine = () => {
        if (!engine) {
            return;
        }
        Matter.World.clear(world);
        Matter.Engine.clear(engine);
        Matter.Render.stop(render);
        Matter.Runner.stop(runner);
        $(render.canvas).remove();
        render.canvas = null;
        render.context = null;
        render.textures = {};
        render = null;
        engine = null;
        world = null;
        runner = null;
    };

    const createEngine = () => {

        destroyEngine();

        const pixelRatio = window.devicePixelRatio || 1;
        // if (pixelRatio > 1.5) {
        //     pixelRatio = 1.5;
        // }

        engine = Matter.Engine.create();
        world = engine.world;
        render = Matter.Render.create({
            element: canvasContainer,
            engine,
            options: {
                width: stageW * pixelRatio,
                height: stageH * pixelRatio,
                wireframes: false,
                showBounds: false,
                background: 'transparent'
            }
        });

        world.gravity.y = 0;

        // Add mouse control
        mouse = Matter.Mouse.create(render.canvas);
        mouseConstraint = Matter.MouseConstraint.create(engine, {
            mouse,
            constraint: {
                stiffness: 0.3,
                render: {
                    visible: false
                }
            }
        });

        mouse.element.removeEventListener('mousewheel', mouse.mousewheel);
        mouse.element.removeEventListener('DOMMouseScroll', mouse.mousewheel);

        mouse.element.removeEventListener('touchmove', mouse.mousemove);
        mouse.element.removeEventListener('touchstart', mouse.mousedown);
        mouse.element.removeEventListener('touchend', mouse.mouseup);

        mouse.element.addEventListener('touchstart', onTouchStartCheck);
        mouse.element.addEventListener('touchend', onTouchEndCheck);

        Matter.Composite.add(world, mouseConstraint);

        // Keep the mouse in sync with rendering
        render.mouse = mouse;

        // Turn off pointer events for everything when the user starts to drag bodies
        Matter.Events.on(mouseConstraint, 'startdrag', () => {
            hasInteracted = true;
            $('body,.pointer-events-auto').css({ pointerEvents: 'none' });
        });

        // Reset pointer events when the user stops dragging bodies
        Matter.Events.on(mouseConstraint, 'enddrag', () => {
            $('body,.pointer-events-auto').css({ pointerEvents: '' });
        });

        // Fit the render viewport to the scene
        Matter.Render.lookAt(render, {
            min: { x: 0, y: 0 },
            max: { x: stageW, y: stageH }
        });

        gsap.set(render.canvas, {
            position: 'absolute',
            width: stageW,
            height: stageH,
            top: 0,
            left: 0,
            zIndex: 1,
            pointerEvents: 'auto'
        });

        Matter.Render.run(render);

        // create runner
        runner = Matter.Runner.create();
        Matter.Runner.run(runner, engine);

        Matter.Events.on(runner, 'afterTick', onAfterTick);
    };

    const createScene = () => {

        // Create boundaries
        const boundaryWidth = 200;
        const boundaryStageH = Viewport.height;
        const boundaryOpts = {
            isStatic: true,
            render: { visible: false }
        };
        Matter.World.add(world, [
            Matter.Bodies.rectangle(stageW / 2, -boundaryWidth / 2, stageW, boundaryWidth, boundaryOpts), // Top
            Matter.Bodies.rectangle(stageW / 2, boundaryStageH + (boundaryWidth / 2), stageW, boundaryWidth, boundaryOpts), // Bottom
            Matter.Bodies.rectangle(-boundaryWidth / 2, boundaryStageH / 2, boundaryWidth, boundaryStageH, boundaryOpts), // Left
            Matter.Bodies.rectangle(stageW + (boundaryWidth / 2), boundaryStageH / 2, boundaryWidth, boundaryStageH, boundaryOpts) // Right
        ]);

        // Get the visible logo
        const logoSvg = getLogoSvg();
        if (!logoSvg) {
            console.warn('No logo svg found');
            return;
        }

        // Create the letter bodies
        bodies = Object.keys(textures).map(id => {
            const shape = $(logoSvg).find(`#letter-${id}`).get(0);
            const { width: svgWidth, height: svgHeight, url: texture } = textures[id];
            const { width, height } = shape.getBoundingClientRect();
            const { left, top } = $(shape).offset();
            const xScale = width / svgWidth;
            const yScale = height / svgHeight;
            originalPositions[id] = {
                x: left + (width * 0.5),
                y: top + (height * 0.5)
            };
            const position = cachedPositions[id] || originalPositions[id];
            const { x, y, angle } = position;
            // TODO normalize x and y so that they are always within bounds
            const body = Matter.Bodies.rectangle(x, y, width, height, {
                label: id,
                friction: 0.2,
                frictionAir: 0.03,
                restitution: 0,
                render: { sprite: { texture, xScale, yScale } }
            });
            Matter.Composite.add(world, body);
            if (angle) {
                Matter.Body.rotate(body, angle);
            }
            return body;
        });

    };

    const onResize = () => {
        if (Viewport.width === stageW) {
            return;
        }
        stageW = Viewport.width;
        stageH = $el.height();
        if (!Matter) {
            return;
        }
        createEngine();
        createScene();
    };

    const onViewportHeightChange = () => {
        if (Math.abs(Viewport.height - stageH) > 100) {
            stageW = null;
            onResize(true);
        }
    };

    const destroy = () => {
        destroyEngine();
        Viewport.off('resize', onResize);
        Dispatch.off(VIEWPORT_HEIGHT_CHANGE, onViewportHeightChange);
        $el.off('mousemove mouseleave');
        if (observer) {
            observer.disconnect();
        }
    };

    const maybeInitMatter = () => {
        if (matterHasInited || !Matter || !texturesAreLoaded) {
            return;
        }
        matterHasInited = true;
        try {
            createEngine();
            createScene();
        } catch (error) {
            console.error(error);
            dispatchEvent(el, 'matter_logo_failed');
            destroy();
            return;
        }

        $el.on('mousemove', e => {
            if (Matter.Query.point(world.bodies, render.mouse.position).length > 0) {
                if (e.buttons === 1) {
                    $el.get(0).style.cursor = 'grabbing';
                } else {
                    $el.get(0).style.cursor = 'grab';
                }
            } else {
                $el.get(0).style.cursor = '';
            }
        });
        $el.on('mouseleave', e => {
            render.mouse.mouseup(e);
        });
        gsap.set($el.find('[data-logo-wrapper]').get(0), { opacity: 0 });
        requestAnimationFrame(() => {
            dispatchEvent(el, 'matter_logo_ready');
        });
    };

    const onObserve = entries => {
        const { isIntersecting } = entries[0];
        console.log('matter', { isIntersecting, matterHasInited, hasInteracted });
        if (!matterHasInited || !hasInteracted || isIntersecting) {
            return;
        }
        // Reset the thing
        hasInteracted = false;
        cachedPositions = {};
        createEngine();
        createScene();
    };

    const init = () => {

        // Preload textures
        const textureUrls = Object.values(textures).map(texture => texture.url);

        let numLoadedTextures = 0;

        textureUrls.forEach(url => {
            const img = new Image();
            img.onload = () => {
                img.onload = null;
                numLoadedTextures += 1;
                texturesAreLoaded = numLoadedTextures >= textureUrls.length;
                maybeInitMatter();
            };
            img.src = url;
        });

        // Load Matter.js async
        loadMatter(matterjs => {
            Matter = matterjs;
            maybeInitMatter();
        });

        observer = new IntersectionObserver(onObserve);
        observer.observe(el);

        Viewport.on('resize', onResize);

        Dispatch.on(VIEWPORT_HEIGHT_CHANGE, onViewportHeightChange);
        Dispatch.emit(COMPONENT_INIT);
    };

    return {
        init,
        destroy
    };

};
