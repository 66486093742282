import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import gsap from 'gsap';
import { COMPONENT_INIT } from '../../../lib/events';

export default el => {

    const $el = $(el);

    let lottie;

    const onClick = e => {
        const { triggerTarget: target } = e;
        gsap.timeline()
            .to(target, { scale: 0.95, duration: 0.05, ease: 'none', transformOrigin: 'center bottom' }, 0)
            .to(target, { scale: 1, duration: 0.05, transformOrigin: 'center bottom' }, 0.05);
        if (lottie) {
            lottie.goToAndPlay(153, true);
        }
    };

    const onLottieFrame = () => {
        if (lottie.currentFrame >= 212) {
            lottie.goToAndStop(153, true);
        }
    };

    const onReady = e => {
        lottie = e.detail.lottie;
        lottie.goToAndStop(153, true);
        lottie.addEventListener('enterFrame', onLottieFrame);
    };

    const init = () => {
        $el.find('[data-lottie]').on('lottie_ready', onReady);
        $el.on('click', onClick);
        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        $el.find('[data-lottie]').off('lottie_ready');
        $el.off('click');
    };

    return {
        init,
        destroy
    };

};
