import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Dispatch from '@vaersaagod/tools/Dispatch';
import gsap from 'gsap';

import { VIEWPORT_HEIGHT_CHANGE } from './events';

let hasInited = false;
let nodes = [];
let elements;
let raf;

let viewportWidth = Viewport.width;
let viewportHeight = Viewport.height;

const parallax = () => {
    const { scrollTop } = Viewport;
    nodes.forEach(node => {
        const { offset, distance } = elements.get(node);
        const objectDistanceFromViewportCenter = offset - (scrollTop + (viewportHeight / 2));
        console.log({ objectDistanceFromViewportCenter });
        const moveDistance = (viewportHeight * (distance / 100));
        const y = (objectDistanceFromViewportCenter / viewportHeight) * moveDistance;
        gsap.set(node, { y, willChange: 'transform' });
    });
    raf = null;
};

const onScroll = () => {
    if (raf) {
        cancelAnimationFrame(raf);
    }
    raf = requestAnimationFrame(parallax);
};

const update = () => {
    elements = new WeakMap();
    nodes = $('[data-parallax]').get();
    if (!nodes.length) {
        return;
    }
    gsap.set(nodes, { clearProps: 'y' });
    const { name: breakpoint } = Viewport.breakpoint;
    let breakpoints = ['s', 'sp', 'm', 'mp', 'l', 'lp', 'xl'];
    breakpoints = breakpoints.slice(0, breakpoints.indexOf(breakpoint) + 1).reverse().concat(['min']);
    nodes.forEach(node => {
        // Find the distance/factor for the current breakpoint
        const values = node.dataset.parallax.split(',').reduce((carry, value) => {
            const temp = value.split(':');
            const key = temp.length === 2 ? temp[0] : 'min';
            const factor = parseInt(temp.length === 2 ? temp[1] : temp[0], 10);
            return {
                ...carry,
                [key]: factor || null
            };
        }, {});
        const distance = breakpoints.reduce((carry, bp) => carry || parseFloat(values[bp] || 0), 0);
        elements.set(node, {
            offset: $(node).parent().offset().top + ($(node).parent().height() * 0.5),
            distance
        });
    });
    console.info('parallax nodes created');
};

const init = () => {
    if (!hasInited) {
        Viewport.on('scroll', onScroll);
        Viewport.on('resize', () => {
            if (Viewport.width === viewportWidth) {
                return;
            }
            viewportWidth = Viewport.width;
            update();
            onScroll();
        });
        Dispatch.on(VIEWPORT_HEIGHT_CHANGE, (key, data) => {
            if (data === viewportHeight) {
                return;
            }
            viewportHeight = data;
            update();
            onScroll();
        }, true);
        hasInited = true;
    }
    update();
    onScroll();
};

export default {
    init,
    update: init
};
