import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import Viewport from '@vaersaagod/tools/Viewport';
import gsap from 'gsap';
import { QUIZ_QUESTION_COMPLETED } from '../../lib/events';
import { rgb2hex } from '../../lib/helpers';

const CANVAS_WIDTH = 520;

export default el => {

    const $el = $(el);
    const canvas = $el.find('canvas').get(0);
    const container = $(canvas).parent().get(0);
    const ctx = canvas.getContext('2d');
    const pencil = $el.find('[data-pencil]').get(0);

    let steps = [];
    let isDrawing = false;

    let canvasScale;
    let canvasColor;

    const isTouch = () => $('html').hasClass('using-touch');

    const renderDrawing = () => {
        if (!steps.length) {
            return;
        }
        const from = steps[steps.length - 2] || steps[0];
        const to = steps[steps.length - 1];
        ctx.beginPath(); // begin
        ctx.lineWidth = 6;
        ctx.lineCap = 'round';
        ctx.strokeStyle = canvasColor;
        ctx.moveTo(from.x / canvasScale, from.y / canvasScale); // from
        if (to.drag) {
            ctx.lineTo(to.x / canvasScale, to.y / canvasScale); // to
        }
        ctx.stroke(); // draw it!
    };

    const getStepFromEvent = e => ({
        x: (e.pageX / canvasScale) - ($(canvas).offset().left / canvasScale),
        y: (e.pageY / canvasScale) - ($(canvas).offset().top / canvasScale)
    });

    const onCanvasMouseDown = e => {
        isDrawing = true;
        const step = getStepFromEvent(e);
        steps.push({ ...step, drag: false });
        renderDrawing();
    };

    const onCanvasMouseUp = () => {
        isDrawing = false;
    };

    const setCanvasSize = () => {
        const { width, height } = container.getBoundingClientRect();
        const canvasRatio = width / height;
        canvas.width = CANVAS_WIDTH;
        canvas.height = CANVAS_WIDTH / canvasRatio;
        $(canvas).css({ width, height });
        canvasScale = width / CANVAS_WIDTH;
        ctx.scale(canvasScale, canvasScale);
        const existingSteps = steps.slice(0);
        steps = [];
        existingSteps.forEach(step => {
            steps.push(step);
            renderDrawing();
        });
    };

    const setCanvasColor = () => {
        const color = $el.find('[data-colorbtn].is-active').eq(0).find('[data-color]').css('background-color');
        canvasColor = rgb2hex(color);
    };

    const movePencil = ({ x, y }) => {
        if (isTouch()) {
            pencil.hidden = true;
            return;
        }
        pencil.hidden = false;
        gsap.set(pencil, { x, y });
        $(canvas).css({ cursor: 'none' });
    };

    const onCanvasMouseMove = e => {
        movePencil({
            x: e.pageX - $(canvas).offset().left,
            y: e.pageY - $(canvas).offset().top
        });
        if (!isDrawing) {
            return;
        }
        e.preventDefault();
        const step = getStepFromEvent(e);
        steps.push({ ...step, drag: true });
        renderDrawing();
    };

    const onCanvasMouseLeave = () => {
        pencil.hidden = true;
    };

    const onClearBtnClick = () => {
        steps = [];
        setCanvasSize();
    };

    const onColorBtnClick = e => {
        const { triggerTarget: target } = e;
        if ($(target).hasClass('is-active')) {
            return;
        }
        $el.find('[data-colorbtn].is-active').removeClass('is-active');
        $(target).addClass('is-active');
        setCanvasColor();
    };

    const init = () => {
        $el.on('click', '[data-solutionbtn]', () => {
            Dispatch.emit(QUIZ_QUESTION_COMPLETED, { result: 'complete' });
        });
        $el.on('click', '[data-clearbtn]', onClearBtnClick);
        $el.on('click', '[data-colorbtn]', onColorBtnClick);
        $(canvas).on('mousedown touchstart', onCanvasMouseDown);
        $(canvas).on('mouseup touchend mouseleave', onCanvasMouseUp);
        $(canvas).on('mousemove touchmove', onCanvasMouseMove);
        $(canvas).on('mouseleave', onCanvasMouseLeave);
        Viewport.on('resize', setCanvasSize);
        setCanvasSize();
        setCanvasColor();
    };

    const destroy = () => {
        $el.off('click');
        $(canvas).off('mousedown mouseup mousemove mouseleave touchstart touchend touchmove');
        Viewport.off('resize', setCanvasSize);
    };

    return {
        init,
        destroy
    };

};
