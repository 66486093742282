import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import gsap from 'gsap';

export default el => {

    const lottie = $(el).find('[data-lottie]').get(0);

    let animation;
    let tl;
    let offset;
    let height;
    let viewportH;

    const setProgress = () => {
        if (!tl) return;
        const { scrollTop } = Viewport;
        const startPos = offset - (viewportH * 0.75);
        const endPos = height + (viewportH * 0.5);
        const currentPos = scrollTop - startPos;
        const progress = Math.max(0, Math.min((currentPos / endPos), 1));
        tl.progress(progress);
    };

    const createTimeline = () => {
        if (tl) tl.invalidate().kill();
        if (!animation) return;
        const frame = { frame: 0 };
        tl = gsap.timeline({ paused: true })
            .fromTo(frame, { frame: 1 }, {
                frame: animation.totalFrames - 10,
                duration: 100,
                ease: 'none',
                onUpdate: () => {
                    try {
                        animation.goToAndStop(frame.frame, true);
                    } catch (error) {
                        console.warn(error);
                    }
                }
            });
        setProgress();
    };

    const onScroll = () => {
        setProgress();
    };

    const onResize = () => {
        offset = $(el).offset().top;
        height = $(el).height();
        viewportH = Viewport.height;
        onScroll();
    };

    const onLottieReady = e => {
        animation = e.detail.lottie;
        createTimeline();
    };

    const init = () => {
        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);
        onResize();
        lottie.addEventListener('lottie_ready', onLottieReady);
    };

    const destroy = () => {
        lottie.removeEventListener('lottie_ready', onLottieReady);
        Viewport.off('resize', onResize);
        Viewport.off('scroll', onScroll);
    };

    return {
        init,
        destroy
    };

};
