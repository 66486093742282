import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import gsap from 'gsap';

const lazySizes = require('lazysizes');

export default el => {

    const lottie = $(el).find('[data-lottie]').get(0);

    let animation;
    let tl;
    let offset;
    let height;
    let viewportH;

    const setProgress = () => {
        if (!tl) return;
        const { scrollTop } = Viewport;
        const startPos = offset - (viewportH * 0.75);
        const endPos = height + (viewportH * 0.65);
        const currentPos = scrollTop - startPos;
        const progress = Math.max(0, Math.min((currentPos / endPos), 1));
        tl.progress(progress);
    };

    const createTimeline = () => {
        if (tl) tl.invalidate().kill();
        if (!animation) return;
        const frame = { frame: 0 };
        tl = gsap.timeline({ paused: true })
            .fromTo(frame, { frame: 1 }, {
                frame: animation.totalFrames - 1,
                duration: 100,
                ease: 'none',
                onUpdate: () => {
                    try {
                        requestAnimationFrame(() => {
                            animation.goToAndStop(frame.frame, true);
                        });
                    } catch (error) {
                        console.warn(error);
                    }
                }
            });
        setProgress();
    };

    const onScroll = () => {
        setProgress();
    };

    const onResize = () => {
        offset = $(el).offset().top;
        height = $(el).height();
        viewportH = Viewport.height;
        onScroll();
    };

    const onLottieReady = e => {
        animation = e.detail.lottie;
        createTimeline();
    };

    const init = () => {

        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);

        onResize();

        const onLoaded = () => {
            requestAnimationFrame(() => {
                gsap.fromTo(el, { opacity: 0 }, { opacity: 1, duration: 0.5, delay: 0.1, ease: 'Cubic.easeIn' });
            });
        };

        // Don't show anything until the tunnel and track has lazyloaded
        const $images = $(el).find('img.lazyload:not(.lazyloaded)');
        $images.css({ transition: 'none' });
        const totalToLoad = $images.length + 1;
        let numLoaded = 0;
        $images.each(img => {
            $(img).on('lazyloaded', () => {
                numLoaded += 1;
                $(img).off('lazyloaded');
                if (numLoaded >= totalToLoad) {
                    onLoaded();
                }
            });
            lazySizes.loader.unveil(img);
        });

        $(lottie).on('lottie_ready', e => {
            $(lottie).off('lottie_ready');
            onLottieReady(e);
            numLoaded += 1;
            if (numLoaded >= totalToLoad) {
                onLoaded();
            }
        });
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
        Viewport.off('scroll', onScroll);
    };

    return {
        init,
        destroy
    };

};
