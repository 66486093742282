import $ from '@vaersaagod/tools/Dom';


export default el => {

    const $el = $(el);

    const init = () => {

    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };

};
