import $ from '@vaersaagod/tools/Dom';
import Components from '@vaersaagod/tools/Components';
import Viewport from '@vaersaagod/tools/Viewport';
import superagent from '@vaersaagod/tools/request';

import gsap from 'gsap';

export default () => {

    const el = $('#videos-panel').get(0);
    const $panel = $(el).find('[data-modal-panel]').eq(0);
    const $content = $(el).find('[data-modal-content]').eq(0);
    const $overlay = $(el).find('[data-modal-overlay]').eq(0);

    const originalHtml = $content.html();

    let isHidden = !!el.hidden;
    let activeElement = null;

    const hide = () => {
        if (isHidden) return;
        isHidden = true;
        try {
            Viewport.releaseTabbing(activeElement);
        } catch (error) {}
        activeElement = null;
        gsap.timeline({
            onComplete() {
                el.hidden = true;
                $content.html(originalHtml);
            }
        })
            .to($overlay.get(0), { opacity: 0, duration: 0.5 }, 0.25)
            .to($panel.get(0), { xPercent: 100, duration: 0.5, ease: 'Quint.easeIn' }, 0);
    };

    const sizePanel = () => {
        if (isHidden) {
            return;
        }
        gsap.set($panel.get(0), { clearProps: 'width' });
        const items = $content.find('[data-item]').get();
        const itemWidth = Math.ceil($(items[0]).width());
        let offset;
        let itemsPerRow = 0;
        for (let i = 0; i < items.length; i += 1) {
            const { top } = items[i].getBoundingClientRect();
            if (offset && offset !== top) {
                break;
            }
            itemsPerRow += 1;
            offset = top;
        }
        const padding = parseInt($content.css('padding-left').replace('px', ''), 10) * 2;
        const width = Math.ceil(itemsPerRow * itemWidth) + padding;
        gsap.set($panel.get(0), { width });
        console.log('size panel', { itemsPerRow, width });
    };

    const show = () => {
        if (!isHidden) return;
        isHidden = false;
        el.hidden = false;
        activeElement = document.activeElement || null;
        const html = $content.html().replace('data-cmpnt', 'data-component');
        $content.html(html);
        Components.init($content.get(0));
        sizePanel();
        const button = $(el).find('button').get(0);
        gsap.timeline()
            .fromTo($overlay.get(0), { opacity: 0 }, { opacity: 1, duration: 0.5 }, 0)
            .fromTo($panel.get(0), { xPercent: 100 }, { xPercent: 0, duration: 1, ease: 'Quint.easeOut' }, 0)
            .add(() => {
                Viewport.lockTabbing(el, button);
            });
    };

    const onBodyKeyUp = e => {
        if (isHidden) return;
        const key = e.key || e.which || e.keyCode || null;
        if (['Escape', 27].indexOf(key) > -1) {
            hide();
        }
    };

    const onResize = () => {
        sizePanel();
    };

    const destroy = () => {
        $('body').off('keyup', onBodyKeyUp);
        $(el).off('click');
        Viewport.off('resize', onResize);
    };

    const init = () => {
        $('body').on('keyup', onBodyKeyUp);
        $(el).on('click', '[data-modal-closebtn],[data-modal-overlay]', () => {
            hide();
        });
        Viewport.on('resize', onResize);
    };

    init();

    return {
        show,
        hide,
        destroy
    };

};
