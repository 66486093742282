import './lib/polyfills';
import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Components from '@vaersaagod/tools/Components';
import Dispatch from '@vaersaagod/tools/Dispatch';
import gsap from 'gsap';
import Rellax from 'rellax';

import lazySizes from './lib/lazysizes';
import ViewportHeight from './lib/ViewportHeight';
import ParallaxScroll from './lib/ParallaxScroll';
import Revealer from './lib/Revealer';

import { COMPONENT_INIT } from './lib/events';

gsap.defaults({ overwrite: 'auto' });

const init = () => {
    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    ViewportHeight.init();
    lazySizes.init();

    const rellaxes = $('[data-rellax]').get();
    if (rellaxes.length) {
        Rellax('[data-rellax]');
    }

    ParallaxScroll.init();

    Revealer.init();

    Dispatch.on(COMPONENT_INIT, () => {
        Revealer.update();
    });

    // Init links
    const $body = $('body');
    $body.on('mouseover', 'a,button:not([aria-disabled="true"]):not(.disabled)', e => {
        if (!$('html').hasClass('using-mouse')) {
            return;
        }
        const { triggerTarget: target } = e;
        const link = target.classList.contains('link-alt') ? target : $(target).find('.link-alt').get(0);
        if (!link || link.classList.contains('js-hover') || link.classList.contains('is-current')) {
            return;
        }
        link.classList.add('js-hover');
        const tl = gsap.timeline()
            .set(link, {
                backgroundPosition: '0% 100%'
            })
            .to(link, {
                backgroundSize: '100% 0.07em',
                duration: 0.35,
                ease: 'Power2.easeInOut'
            })
            .set(link, {
                backgroundPosition: '100% 100%'
            });
        $(target).on('mouseleave', () => {
            $(target).off('mouseleave');
            link.classList.remove('js-hover');
            tl
                .to(link, {
                    backgroundSize: '0% 0.07em',
                    duration: 0.35,
                    ease: 'Power2.easeInOut'
                })
                .set(link, { clearProps: 'backgroundSize,backgroundPosition' });
        });
    });

    // $('lazy').on('mouseenter', () => {
    //     $(el).find('.lazyload').each(img => lazySizes.loader.unveil(img));
    // });
};

require('doc-ready')(init);
