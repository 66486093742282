import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import gsap from 'gsap';
import Dispatch from '@vaersaagod/tools/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default el => {

    const $el = $(el);

    const init = () => {
        // // If in viewport, do the intro
        // if (Viewport.visible(el)) {
        //     const logo = $el.find('[data-logo]').get(0);
        //     const tl = gsap.timeline();
        //     if (logo) {
        //         tl.fromTo(logo, { yPercent: 50 }, { yPercent: 0, ease: 'Back.easeOut', duration: 0.75 }, 'logo');
        //         tl.fromTo(logo, { opacity: 0 }, { opacity: 1, duration: 0.3 }, 'logo');
        //     }
        //     const nav = $el.find('[data-nav]').get(0);
        //     const navItems = nav ? Array.from(nav.children) : [];
        //     if (navItems.length) {
        //         tl.fromTo(navItems, { yPercent: 10 }, { yPercent: 0, ease: 'Back.easeOut', duration: 0.75, stagger: 0.1 }, 'logo+=0.1');
        //         tl.fromTo(navItems, { opacity: 0 }, { opacity: 1, duration: 0.3, stagger: 0.1 }, 'logo+=0.1');
        //     }
        // }
        // Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
    };

    return {
        init,
        destroy
    };

};
