import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Components from '@vaersaagod/tools/Components';

import gsap from 'gsap';

export default (el, opts = {}) => {

    let isHidden = true;
    let activeElement = null;

    const options = {
        escToClose: true,
        ...opts
    };

    const hide = () => {
        if (isHidden) return;
        isHidden = true;
        const $bg = $(el).find('[data-modal-bg]');
        const $main = $(el).find('[data-modal-main]');
        const $circle = $(el).find('[data-modal-circle]');
        const tl = gsap.timeline({
            onComplete() {
                try {
                    Viewport.releaseTabbing(activeElement && Viewport.visible(activeElement) ? activeElement : null);
                } catch (error) {
                    console.error(error);
                };
                activeElement = null;
                el.hidden = true;
            }
        })
            .to($bg.get(0), { opacity: 0, duration: 0.3 }, 0.3)
            .to($circle.get(0), { scale: 0.5, duration: 0.5, ease: 'Back.easeIn' }, 0)
            .to($main.get(0).children, { scale: 0.5, duration: 0.5, ease: 'Back.easeIn' }, 0)
            .to($circle.get(0), { opacity: 0, duration: 0.3 }, 0.2)
            .to($main.get(0), { opacity: 0, duration: 0.3 }, 0.2)
            .set([$bg.get(0), $circle.get(0), $main.get(0), $main.get(0).children], { clearProps: 'opacity,scale' });
    };

    const show = () => {
        if (!isHidden) return;
        isHidden = false;
        el.hidden = false;
        activeElement = document.activeElement || null;
        const $bg = $(el).find('[data-modal-bg]');
        const $main = $(el).find('[data-modal-main]');
        const $circle = $(el).find('[data-modal-circle]');
        const button = $(el).find('button').get(0);
        try {
            Viewport.lockTabbing(el, button);
        } catch (error) {
            console.error(error);
        }
        gsap.timeline()
            .fromTo($bg.get(0), { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0)
            .fromTo($circle.get(0), { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0)
            .fromTo($circle.get(0), { scale: 0.3 }, { scale: 1, duration: 0.75, ease: 'Back.easeInOut' }, 0)
            .fromTo($main.get(0), { opacity: 0 }, { opacity: 1, duration: 0.5 }, 0.25)
            .add(() => {
                Components.init(el);
            }, 'end-=0.25');
    };

    const setHtml = html => {
        if (isHidden) {
            Components.destroy(el);
            $(el).html(html);
        } else {
            // Update HTML for visible modal
            const $main = $(el).find('[data-modal-main]').eq(0);
            const $newMain = $(`<div>${html}</div>`).find('[data-modal-main]').eq(0);
            gsap.timeline()
                .to($main.get(0), { opacity: 0, duration: 0.3 })
                .add(() => {
                    Components.destroy($main.get(0));
                    $main.html($newMain.html());
                    Components.init($main.get(0));
                })
                .to($main.get(0), { opacity: 1, duration: 0.3 });
        }
    };

    const onBodyKeyUp = e => {
        if (isHidden || !options.escToClose) return;
        const key = e.key || e.which || e.keyCode || null;
        if (['Escape', 27].indexOf(key) > -1) {
            hide();
        }
    };

    const destroy = () => {
        $(el).off('click');
        $('body').off('keyup', onBodyKeyUp);
    };

    const init = () => {
        $(el).on('click', '[data-modal-closebtn]', hide);
        $('body').on('keyup', onBodyKeyUp);
    };

    init();

    return {
        show,
        hide,
        html: setHtml,
        destroy
    };

};
