import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import gsap from 'gsap';

export default el => {

    const $el = $(el);
    const $menuToggle = $el.find('[data-nav] [data-toggle]').eq(0);
    const $menu = $el.find('[data-nav] [data-menu]').eq(0);

    let menuIsOpen = false;
    let focusedElement = null;

    const isSmall = () => ['m', 'mp', 'l', 'lp', 'xl'].indexOf(Viewport.breakpoint.name) === -1;

    const closeMenu = (tween = true) => {
        if (!menuIsOpen) {
            return;
        }
        menuIsOpen = false;
        $menuToggle.attr('aria-expanded', 'false');
        try {
            Viewport.releaseTabbing(focusedElement || $menuToggle.get(0));
            focusedElement = null;
        } catch (error) {
            console.error(error);
        }
        const afterClose = () => {
            $menu.get(0).hidden = true;
        };
        if (!tween) {
            afterClose();
            return;
        }
        gsap.timeline({
            onComplete: () => {
                gsap.killTweensOf($menu.get(0));
                gsap.set($menu.get(0), { clearProps: 'xPercent' });
                afterClose();
            }
        })
            .to($menu.get(0), { xPercent: 100, ease: 'Quint.easeIn', duration: 0.5 });
    };

    const openMenu = (tween = true) => {
        if (menuIsOpen) {
            return;
        }
        menuIsOpen = true;
        $menuToggle.attr('aria-expanded', 'true');
        $menu.get(0).hidden = false;
        try {
            focusedElement = document.activeElement || null;
            Viewport.lockTabbing($menu.get(0));
        } catch (error) {
            console.error(error);
        }
        if (tween) {
            gsap.timeline()
                .fromTo($menu.get(0), { xPercent: 100 }, { xPercent: 0, ease: 'Quint.easeOut', duration: 1 });
        } else {
            gsap.killTweensOf($menu.get(0));
            gsap.set($menu.get(0), { clearProps: 'xPercent' });
        }
    };

    const toggleMenu = () => {
        if (menuIsOpen) {
            closeMenu();
        } else {
            openMenu();
        }
    };

    const onMenuToggleClick = () => {
        toggleMenu();
    };

    const onBreakpoint = () => {
        if (!isSmall()) {
            closeMenu(false);
        }
    };

    const onBodyKey = e => {
        if (!isSmall()) {
            return;
        }
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            closeMenu();
        }
    };

    const onBodyClick = e => {
        if (!isSmall()) {
            return;
        }
        const { target } = e;
        if (target !== el && !el.contains(target)) {
            if (document.activeElement && !el.contains(document.activeElement)) {
                focusedElement = document.activeElement;
            }
            closeMenu();
        }
    };

    const init = () => {
        $menu.on('click', '[data-menu] button,[data-menu] a', () => {
            closeMenu();
        });
        $menuToggle.on('click', onMenuToggleClick);
        Viewport.on('breakpoint', onBreakpoint);
        $('body')
            .on('keyup', onBodyKey)
            .on('click', onBodyClick);
    };

    const destroy = () => {
        $menu.off('click');
        $menuToggle.off('click');
        Viewport.off('breakpoint', onBreakpoint);
        closeMenu(false);
        $('body')
            .off('keyup', onBodyKey)
            .off('click', onBodyClick);
    };

    return {
        init,
        destroy
    };

};
