import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Dispatch from '@vaersaagod/tools/Dispatch';
import gsap from 'gsap';

import { scrollTo } from '../../lib/helpers';
import {
    PROGRAMMATIC_SCROLL_START,
    PROGRAMMATIC_SCROLL_END,
    COMPONENT_INIT
} from '../../lib/events';

const Set = require('es6-set');

require('intersection-observer');

export default el => {

    const $el = $(el);
    const $toggle = $el.find('button[aria-expanded]');
    const $menu = $el.find('[data-menu]');
    const dot = $menu.find('[data-dot]').get(0);

    let hideAtY = null;
    let isHidden = false;

    let focusedElement;
    let sections = [];
    let sectionObserver;

    let activeSectionId;
    let preventSetActiveSection = false;

    const intersectingSections = new Set();

    const isSmall = () => ['m', 'mp', 'l', 'lp', 'xl'].indexOf(Viewport.breakpoint.name) === -1;

    const isExpanded = () => $toggle.attr('aria-expanded') === 'true';

    const setActiveSection = (id, tween = true) => {
        if (!id || preventSetActiveSection) {
            return;
        }
        activeSectionId = id;
        const sectionIds = sections.map(section => section.id);
        const currentSectionIndex = sectionIds.indexOf(activeSectionId);
        const $items = $el.find('[data-item]');
        $items.removeClass('is-active is-complete');
        const $activeItem = $items.eq(currentSectionIndex);
        $activeItem.addClass('is-active');
        $($items.get().slice(0, currentSectionIndex)).addClass('is-complete');
        // Animate the dot
        requestAnimationFrame(() => {
            let { width } = $activeItem.get(0).getBoundingClientRect();
            const left = $activeItem.get(0).offsetLeft;
            let dotX = left - 8;
            if (currentSectionIndex === 0) {
                width += 8;
                dotX -= 8;
            } else if (currentSectionIndex === sections.length - 1) {
                width += 8;
            }
            const props = { width: width + 16, x: dotX, opacity: 1 };
            if (tween) {
                gsap.to(dot, { ...props, duration: 0.3 });
            } else {
                gsap.set(dot, props);
            }
        });
    };

    const maybeSetActiveSection = () => {
        const activeSection = Array.from(intersectingSections).pop() || sections[0];
        setActiveSection(activeSection.id);
    };

    const close = (tween = true) => {
        if (!isExpanded()) return;
        $toggle.attr('aria-expanded', 'false');
        $toggle.find('[data-icon="open"]').get(0).hidden = false;
        $toggle.find('[data-icon="close"],[data-label]').each(node => {
            node.hidden = true;
        });
        try {
            Viewport.releaseTabbing(focusedElement || $toggle.get(0));
            focusedElement = null;
        } catch (error) {
            console.error(error);
        }
        const afterClose = () => {
            $menu.css({ display: '' });
        };
        if (!tween) {
            afterClose();
            return;
        }
        gsap.timeline({
            onComplete: () => {
                gsap.killTweensOf($menu.get(0));
                gsap.set($menu.get(0), { clearProps: 'xPercent' });
                afterClose();
            }
        })
            .to($menu.get(0), { xPercent: 100, ease: 'Quint.easeIn', duration: 0.5 });
    };

    const open = (tween = true) => {
        if (isExpanded()) {
            return;
        }
        $toggle.attr('aria-expanded', 'true');
        $toggle.find('[data-icon="open"]').get(0).hidden = true;
        $toggle.find('[data-icon="close"],[data-label]').each(node => {
            node.hidden = false;
        });
        $menu.css({ display: 'block' });
        try {
            focusedElement = document.activeElement || null;
            Viewport.lockTabbing($el.get(0));
        } catch (error) {
            console.error(error);
        }
        if (tween) {
            gsap.timeline()
                .fromTo($menu.get(0), { xPercent: 100 }, { xPercent: 0, ease: 'Quint.easeOut', duration: 1 });
        } else {
            gsap.killTweensOf($menu.get(0));
            gsap.set($menu.get(0), { clearProps: 'xPercent' });
        }
    };

    const hide = () => {
        if (isHidden) {
            return;
        }
        isHidden = true;
        gsap.timeline({
            onComplete: () => {
                el.hidden = true;
            }
        })
            .to(el, { opacity: 0, duration: 0.3, ease: 'Cubic.easeIn' }, 0);
    };

    const show = () => {
        if (!isHidden) {
            return;
        }
        isHidden = false;
        el.hidden = false;
        gsap.timeline()
            .to(el, { opacity: 1, duration: 0.3 }, 0)
            .fromTo(el, { y: 200 }, { y: 0, ease: 'Back.easeOut', duration: 0.75 }, 0);
    };

    const onToggleClick = () => {
        if (isExpanded()) {
            close();
        } else {
            open();
        }
        focusedElement = $toggle.get(0);
    };

    const onBreakpoint = () => {
        if (!isSmall()) {
            close(false);
        }
    };

    const onBodyKey = e => {
        if (!isSmall()) {
            return;
        }
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            close();
        }
    };

    const onBodyClick = e => {
        if (!isSmall()) {
            return;
        }
        const { target } = e;
        if (target !== el && !el.contains(target)) {
            if (document.activeElement && !el.contains(document.activeElement)) {
                focusedElement = document.activeElement;
            }
            close();
        }
    };

    const onItemClick = e => {
        e.preventDefault();
        const { triggerTarget: target } = e;
        const scrollTargetId = $(target).attr('href').replace('#', '');
        const scrollTarget = $(`#${scrollTargetId}`);
        preventSetActiveSection = false;
        setActiveSection(scrollTargetId);
        scrollTo(scrollTarget);
        if (isSmall()) {
            close();
        }
    };

    const onProgrammaticScrollStart = () => {
        preventSetActiveSection = true;
    };

    const onProgrammaticScrollEnd = () => {
        preventSetActiveSection = false;
        requestAnimationFrame(() => {
            maybeSetActiveSection();
        });
    };

    const onScroll = () => {
        if (hideAtY === null) {
            return;
        }
        const { scrollTop } = Viewport;
        const shouldBeHidden = scrollTop >= hideAtY;
        if (shouldBeHidden && !isHidden) {
            hide();
        } else if (!shouldBeHidden && isHidden) {
            show();
        }
    };

    const onResize = () => {
        setActiveSection(activeSectionId, false);
        const footer = $('#bottom').get(0);
        if (!footer) {
            return;
        }
        hideAtY = $(footer).offset().top - $el.height() - (Viewport.height * 0.75);
        onScroll();
    };

    const init = () => {

        isHidden = true;
        gsap.set(el, { opacity: 0 });

        $toggle.on('click', onToggleClick);
        $el.on('click', '[data-item]', onItemClick);
        $('body')
            .on('keyup', onBodyKey)
            .on('click', onBodyClick);
        const hrefs = $el.find('[data-item]').get().map(item => $(item).attr('href'));
        sections = $(hrefs.join(',')).get();
        if (sections.length) {
            sectionObserver = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    const { isIntersecting, target } = entry;
                    if (isIntersecting) {
                        intersectingSections.add(target);
                    } else {
                        intersectingSections.delete(target);
                    }
                });
                requestAnimationFrame(() => {
                    maybeSetActiveSection();
                });
            }, {
                rootMargin: '0px',
                threshold: [0.1]
            });
            sections.forEach(section => sectionObserver.observe(section));
        }

        Viewport.on('scroll', onScroll);
        Viewport.on('resize', onResize);
        Viewport.on('breakpoint', onBreakpoint);

        onResize();

        Dispatch.on(PROGRAMMATIC_SCROLL_START, onProgrammaticScrollStart);
        Dispatch.on(PROGRAMMATIC_SCROLL_END, onProgrammaticScrollEnd);

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        $toggle.off('click');
        $el.off('click');
        $('body')
            .off('keyup', onBodyKey)
            .off('click', onBodyClick);
        Viewport.releaseTabbing();
        if (sectionObserver) {
            sectionObserver.disconnect();
            sectionObserver = null;
        }
        Viewport.off('scroll', onScroll);
        Viewport.off('resize', onResize);
        Viewport.off('breakpoint', onBreakpoint);
        Dispatch.off(PROGRAMMATIC_SCROLL_START, onProgrammaticScrollStart);
        Dispatch.off(PROGRAMMATIC_SCROLL_END, onProgrammaticScrollEnd);
    };

    return {
        init,
        destroy
    };

};
