import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import { QUIZ_QUESTION_COMPLETED } from '../../lib/events';

export default el => {

    const $el = $(el);

    const init = () => {
        $el.on('submit', e => {
            e.preventDefault();
            Dispatch.emit(QUIZ_QUESTION_COMPLETED, { result: 'skipped' });
        });
    };

    const destroy = () => {
        $el.off('submit');
    };

    return {
        init,
        destroy
    };

};
