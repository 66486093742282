import $ from '@vaersaagod/tools/Dom';

import gsap from 'gsap';

export default el => {

    const $el = $(el);
    const things = $el.find('[data-thing]').get();

    const random = (min, max) => {
        const delta = max - min;
        return (direction = 1) => (min + delta * Math.random()) * direction;
    };

    const randomX = random(1, 10);
    const randomY = random(1, 10);
    const randomTime = random(3, 5);
    const randomTime2 = random(5, 10);
    const randomAngle = random(-10, 10);

    const rotate = (target, direction) => {

        gsap.to(target, randomTime2(), {
            rotation: randomAngle(direction),
            ease: 'none',
            onComplete: rotate,
            onCompleteParams: [target, direction * -1]
        });
    };

    const moveX = (target, direction) => {

        gsap.to(target, randomTime(), {
            x: randomX(direction),
            ease: 'none',
            onComplete: moveX,
            onCompleteParams: [target, direction * -1]
        });
    };

    const moveY = (target, direction) => {
        gsap.to(target, randomTime(), {
            y: randomY(direction),
            ease: 'none',
            onComplete: moveY,
            onCompleteParams: [target, direction * -1]
        });
    };

    const init = () => {
        things.forEach(thing => {
            gsap.set(thing, {
                x: randomX(-1),
                y: randomX(1),
                rotation: randomAngle(-1)
            });
            moveX(thing, 1);
            moveY(thing, -1);
            rotate(thing, 1);
        });
    };

    const destroy = () => {
        gsap.killTweensOf(things);
    };

    return {
        init,
        destroy
    };

};
