import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import Viewport from '@vaersaagod/tools/Viewport';
import { loadFlickity } from '../../lib/async-bundles';
import { QUIZ_QUESTION_ANSWERED, QUIZ_QUESTION_COMPLETED } from '../../lib/events';

import gsap from 'gsap';

const WRONG_ANSWER_CLEAR_TIMEOUT = 500;

export default (el, { correctAnswers, slider = false }) => {

    const $el = $(el);

    let isFinished = false;
    let wrongAnswerTimeout = null;

    let flkty;

    const clearWrongAnswers = () => {
        if (wrongAnswerTimeout) {
            clearTimeout(wrongAnswerTimeout);
            wrongAnswerTimeout = null;
        }
        $el.find('[data-choice].is-wrong').each(node => {
            $(node).removeClass('is-wrong').find('input').get(0).checked = false;
        });
    };

    const onChange = e => {
        const $choice = $(e.target).parent('[data-choice]');
        if (isFinished || $choice.hasClass('is-correct')) {
            return;
        }
        clearWrongAnswers();
        const isCorrect = correctAnswers.indexOf($choice.data('choice')) > -1;
        if (isCorrect) {
            $choice.addClass('is-correct');
            isFinished = $el.find('[data-choice].is-correct').length === correctAnswers.length;
            if (isFinished) {
                $el.css({ pointerEvents: 'none' });
                Dispatch.emit(QUIZ_QUESTION_ANSWERED);
                Dispatch.emit(QUIZ_QUESTION_COMPLETED, { result: 'complete' });
            } else {
                Dispatch.emit(QUIZ_QUESTION_ANSWERED, { result: 'correct' });
            }
        } else {
            wrongAnswerTimeout = setTimeout(() => {
                clearWrongAnswers();
            }, WRONG_ANSWER_CLEAR_TIMEOUT);
            $choice.addClass('is-wrong');
            Dispatch.emit(QUIZ_QUESTION_ANSWERED, { result: 'incorrect' });
        }
    };

    const maybeCreateSlider = () => {

        if (flkty) {
            flkty.destroy();
            flkty = null;
        }

        const choices = $el.find('[data-choice]').get();
        const { offsetTop } = choices[0];

        for (let i = 1; i < choices.length; i++) {
            if (choices[i].offsetTop !== offsetTop) {
                gsap.to(el, { opacity: 1, duration: 0.3 });
                return;
            }
        }

        loadFlickity(Flickity => {
            flkty = new Flickity(el, {
                pageDots: false,
                adaptiveHeight: false,
                prevNextButtons: false,
                setGallerySize: true,
                groupCells: true,
                contain: false,
                cellAlign: 'center',
                resize: false,
                wrapAround: false,
                percentPosition: true,
                accessibility: true,
                dragThreshold: 10,
                lazyLoad: false,
                on: {
                    dragStart: () => {
                        $el.find('a,button').css({ pointerEvents: 'none' });
                    },
                    dragEnd: () => {
                        $el.find('a,button').css({ pointerEvents: '' });
                    }
                }
            });

            gsap.to(el, { opacity: 1, duration: 0.3 });

        });

    };

    const onResize = () => {
        if (!slider) return;
        maybeCreateSlider();
    };

    const init = () => {
        $el.find('[data-choice]').on('change', onChange);
        Viewport.on('resize', onResize);
        onResize();
    };

    const destroy = () => {
        $el.find('[data-choice]').off('change');
        Viewport.off('resize', onResize);
        if (flkty) {
            flkty.destroy();
        }
    };

    return {
        init,
        destroy
    };

};
