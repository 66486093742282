import $ from '@vaersaagod/tools/Dom';
import gsap from 'gsap';

export default el => {

    let clicked = false;

    $(el).on('click', () => {
        gsap.timeline()
            .to(el, { scale: 0.95, duration: 0.05, ease: 'none', transformOrigin: 'center top' }, 0)
            .to(el, { scale: 1, duration: 0.05, transformOrigin: 'center top' }, 0.05);
        if (clicked) {
            return;
        }
        $(el).find('[data-default]').addClass('invisible');
        $(el).find('[data-clicked]').removeClass('invisible');
        clicked = true;
    });

    return {
        destroy() {
            $(el).off('click');
        }
    };

};
