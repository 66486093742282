import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Config from '@vaersaagod/tools/Config';
import gsap from 'gsap';

require('intersection-observer');

const defaultStaggers = {
    x: 100,
    y: 100,
    chars: 100,
    default: 50
};

const defaultDurations = {
    x: 1,
    y: 1,
    default: 0.35
};

let currentScrollTop = -1;
let direction = 'down';
let observer;
let intersecting = [];
let tweens = new WeakMap();
let timeouts = new WeakMap();
let $els;

const playTween = tween => {
    if (Config.get('prefersReducedMotion')) {
        tween.pause(tween.duration(), false);
    } else {
        tween.play();
    }
};

const getTween = el => {

    let tween = tweens.get(el);
    if (tween) {
        return tween;
    }

    const type = el.dataset.reveal || null;

    if (type === 'show') {
        // No animation, the item should just be unhidden after the page loads
        return null;
    }

    if (el.hasAttribute('data-reveal-fold') && !Viewport.visible(el)) {
        // This animation should only be created if the element is visible/above the fold
        return null;
    }

    let duration = el.dataset.revealDuration;
    if (duration === undefined || !duration.length) {
        duration = defaultDurations[type] || defaultDurations.default || 1;
    }
    duration = parseFloat(duration);

    tween = gsap.timeline({
        paused: true
    });

    if (type === 'y') {

        const y = parseInt(el.dataset.revealY || 0, 10) || 150;
        tween
            .fromTo(el, {
                y
            }, {
                y: 0.001,
                ease: 'Quint.easeOut',
                duration
            }, 0)
            .fromTo(el, {
                opacity: 0
            }, {
                opacity: 1,
                duration: duration * 0.5,
                ease: 'Sine.easeOut'
            }, 0);

    } else if (type === 'chars') {

        const chars = $(el).find('[data-char]').get();

        if (!chars.length) {
            return null;
        }

        const stagger = 0.01;

        tween
            .fromTo(chars, {
                yPercent: 50
            }, {
                yPercent: 0,
                duration: 0.75,
                transformOrigin: 'center bottom',
                ease: 'Cubic.easeOut',
                stagger
            }, 0)
            .fromTo(chars, {
                opacity: 0
            }, {
                opacity: 1,
                duration: 0.5,
                ease: 'Cubic.easeOut',
                stagger
            }, 0);

    } else {
        // Default is fade
        tween
            .fromTo(el, { opacity: 0 }, {
                opacity: 1,
                duration,
                ease: 'Quad.easeIn'
            });
    }

    tweens.set(el, tween);

    return tween;
};

const onScroll = () => {
    const { scrollTop } = Viewport;
    direction = scrollTop > currentScrollTop ? 'down' : 'up';
    currentScrollTop = scrollTop;
};

const scrollHandler = onScroll;

const createWaypoints = () => {
    $('[data-reveal]:not(.js-reveal):not([hidden])').each(el => {
        el.classList.add('js-reveal');
        const tween = getTween(el);
        if (tween) {
            observer.observe(el);
        }
    });
};

const init = () => {

    if ($('html').hasClass('is-live-preview')) {
        return;
    }

    onScroll();

    observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {

            const { target, intersectionRatio } = entry;
            let { isIntersecting } = entry;

            // Get tween
            const tween = getTween(target);
            let timeout = timeouts.get(target);
            if (timeout) {
                clearTimeout(timeout);
                timeouts.delete(target);
            }

            let threshold = target.dataset.revealThreshold;
            if (threshold !== undefined) {
                threshold = parseFloat(threshold);
            }

            if (threshold && intersectionRatio < threshold) {
                isIntersecting = false;
            }

            const alwaysReveal = target.hasAttribute('data-reveal-always');

            if (isIntersecting) {
                intersecting.push(target);
                // Easiest way I could think of to sort the array of intersecting elements according to their chronological position in the DOM (which is a good idea)
                intersecting = $('.js-reveal').get().filter(node => intersecting.indexOf(node) > -1);
            } else {
                intersecting = intersecting.filter(node => node !== target);
            }

            const { top } = target.getBoundingClientRect();

            if (!isIntersecting && direction === 'up' && top >= Viewport.height) {
                // Reset the effect
                tween.pause(0, false);
                return;

            }

            // Calculate base stagger
            let stagger = target.dataset.revealStagger;
            if (stagger === undefined || !stagger.length) {
                const type = target.dataset.reveal;
                stagger = defaultStaggers[type] || 0;
            }
            stagger = parseInt(stagger, 10);

            requestAnimationFrame(() => {

                if (!isIntersecting && top < 0) {

                    if (alwaysReveal) {
                        playTween(tween);
                    } else {
                        tween.pause(tween.duration(), false);
                    }

                } else if (isIntersecting && !tween.progress()) {

                    if (Config.get('prefersReducedMotion')) {
                        playTween(tween);
                        return;
                    }

                    stagger *= Math.max(0, intersecting.filter(node => getTween(node) && getTween(node).progress() <= 0.05).indexOf(target));

                    if (!stagger) {
                        playTween(tween);
                        return;
                    }

                    timeout = setTimeout(() => {
                        clearTimeout(timeout);
                        timeouts.delete(target);
                        tween.play();
                    }, stagger);
                }

            });

        });
    }, {
        threshold: [0, 0.5, 1, 0],
        //rootMargin: '0px 0px 100px 0px'
        rootMargin: '0px 0px 150px 0px'
    });

    createWaypoints();

    window.addEventListener('scroll', scrollHandler);

};

const update = () => {
    if (!observer) {
        return;
    }
    observer.takeRecords().map(({ target }) => target).forEach(node => {
        if (!node.closest('html')) {
            observer.unobserve(node);
            const tween = tweens.get(node);
            if (tween) {
                tweens.delete(node);
                tween.kill();
            }
            const timeout = timeouts.get(node);
            if (timeout) {
                timeouts.delete(node);
                clearTimeout(timeout);
            }
        }
    });
    createWaypoints();
};

const destroy = () => {
    window.removeEventListener('scroll', scrollHandler);
    observer.disconnect();
    observer = null;
    $els.removeClass('js-reveal');
    gsap.killTweensOf($els.get());
    gsap.set($els.get(), { clearProps: 'all' });
    tweens = new WeakMap();
    timeouts = new WeakMap();
};

export default ({
    init,
    update,
    destroy
});
