import $ from '@vaersaagod/tools/Dom';

import gsap from 'gsap';
import { getDocHeight } from '../../../lib/helpers';

export default el => {

    const $el = $(el);
    const garbage = $el.find('[data-garbage]').get();

    let clicked = false;

    $el.on('click', () => {

        gsap.timeline()
            .to(el, { scale: 0.95, duration: 0.05, ease: 'none', transformOrigin: 'center top' }, 0)
            .to(el, { scale: 1, duration: 0.05, transformOrigin: 'center top' }, 0.05);

        if (clicked) {
            return;
        }

        clicked = true;

        $el.find('[data-default]').addClass('invisible');
        $el.find('[data-clicked]').removeClass('invisible');

        const y = getDocHeight() - 100;

        gsap.timeline()
            .to(garbage.reverse(), {
                y,
                duration: y / 1500,
                ease: 'none',
                stagger: 0.05,
                rotate: -360
            });
    });

    return {
        destroy() {
            $el.off('click mouseenter');
        }
    };

};
