import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';

import gsap from 'gsap';

const lazySizes = require('lazysizes');

export const Preloader = (el, { onComplete }) => {

    const bar = $(el).find('[data-progress]').get(0);

    gsap.set(bar, { width: 0, transformOrigin: 'left center' });

    let total = 0;
    let numLoaded = 0;

    const onItemLoaded = () => {
        numLoaded += 1;
        const progress = numLoaded / total;
        const isComplete = progress >= 1;
        const width = `${progress * 100}%`;
        gsap.to(bar, {
            width,
            duration: 0.3,
            transformOrigin: 'left center',
            ease: 'none',
            onComplete() {
                if (!isComplete) {
                    return;
                }
                total = 0;
                numLoaded = 0;
                if (onComplete) {
                    onComplete();
                }
            }
        });
    };

    const loadLotties = lotties => {
        lotties.forEach(lottie => {
            if (lottie.offsetParent === null || !Viewport.visible(lottie)) {
                return;
            }
            total += 1;
            lottie.addEventListener('lottie_ready', onItemLoaded);
        });
    };

    const loadImages = images => {
        images.forEach(img => {
            if ($(img).hasClass('lazyloaded') || !Viewport.visible(img)) {
                return;
            }
            total += 1;
            $(img).addClass('lazypreload');
            img.addEventListener('lazyloaded', onItemLoaded);
            lazySizes.loader.unveil(img);
        });
    };

    const show = () => {
        el.hidden = false;
        gsap.fromTo(el.children, { opacity: 0 }, { opacity: 1, duration: 0.3 });
    };

    const hide = () => {
        el.hidden = true;
    };

    const destroy = () => {
        hide();
        total = 0;
        numLoaded = 0;
    };

    return {
        show,
        hide,
        destroy,
        loadLotties,
        loadImages
    };

};
