import $ from '@vaersaagod/tools/Dom';

import gsap from 'gsap';

export default el => {

    const $el = $(el);

    const onClick = () => {
        const $plates = $el.find('[data-plate]');
        gsap.timeline()
            .to($plates.get(), { scale: 0.8, duration: 0.05, ease: 'none' }, 0)
            .to($plates.get(), { scale: 1, duration: 0.05 }, 0.05)
            .to($plates.get(0), { rotation: 720, duration: 1, ease: 'Power3.easeOut' }, 0)
            .to($plates.get(1), { rotation: 720, duration: 1, ease: 'Power4.easeOut' }, 0)
            .set($plates.get(), { clearProps: 'rotation' });
    };

    const init = () => {
        $el.on('click', onClick);
    };

    const destroy = () => {
        $el.off('click');
    };

    return {
        init,
        destroy
    };

};
