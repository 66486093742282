import $ from '@vaersaagod/tools/Dom';
import Components from '@vaersaagod/tools/Components';
import Config from '@vaersaagod/tools/Config';
import Dispatch from '@vaersaagod/tools/Dispatch';
import superagent from '@vaersaagod/tools/request';
import serialize from 'form-serialize';

import gsap from 'gsap';

import {
    QUIZ_PROGRESS_UPDATED,
    QUIZ_QUESTION_ANSWERED,
    QUIZ_QUESTION_COMPLETED,
    REFRESH_CHAT_MESSAGES
} from '../../lib/events';

import Modal from '../../lib/ModalCircle';
import { playSound } from '../../lib/Sound';

export default el => {

    const $el = $(el);

    let request = null;
    let marqueeResetTimeout;
    let responseModal;

    let bgMusicSound;

    const setMarquee = key => {
        if (marqueeResetTimeout) {
            clearTimeout(marqueeResetTimeout);
            marqueeResetTimeout = null;
        }
        const $marquee = $el.find('[data-marquee]');
        const target = $marquee.find(`[data-${key || 'default'}]`).get(0);
        gsap.timeline({
            onComplete() {
                marqueeResetTimeout = setTimeout(() => {
                    setMarquee();
                }, 2000);
            }
        })
            .to($marquee.get(0).children, { opacity: 0, duration: 0.5 }, 0)
            .to(target, { opacity: 1, duration: 0.5 }, 0);
    };

    const showResponseModal = () => {
        // Get the response to show, and update the modal
        const endpoint = `/${Config.get('actionTrigger')}/banorama/quiz/get-response-modal-html`;
        const uid = $el.find('[data-question] form input[name="question"]').val();
        const siteId = $el.find('[data-question] form input[name="siteId"]').val();
        request = superagent.get(endpoint);
        request
            .accept('application/json')
            .query({ uid, siteId })
            .then(({ status, body }) => {
                if (status !== 200 || !body.html) {
                    throw new Error();
                }
                const responseModalHtml = $(`<div>${body.html}</div>`).find('#quiz-response-modal').html();
                responseModal.html(responseModalHtml);
                responseModal.show();
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                request = null;
            });
    };

    const showNextQuestion = () => {
        if (request) {
            return;
        }
        request = superagent.get(window.location.href);
        request
            .then(({ status, text: html }) => {
                if (status !== 200 || !html) {
                    throw new Error();
                }
                // Close the response modal
                responseModal.hide();
                // Destroy existing components
                Components.destroy(el.firstElementChild);
                // Update the question content
                const $html = $('<div />').html(html);
                const $questionContent = $el.find('[data-question-content]');
                const $nextQuestionContent = $html.find('[data-question-content]').eq(0);
                // Update the marquee text
                const $marquee = $el.find('[data-marquee]');
                const $nextMarquee = $html.find('[data-marquee]').eq(0);
                $el.data('message', '');
                Dispatch.emit(REFRESH_CHAT_MESSAGES);
                gsap.timeline()
                    .to($questionContent.get(0), {
                        opacity: 0,
                        duration: 0.5,
                        onComplete() {
                            $questionContent.html($nextQuestionContent.html());
                        }
                    }, 0)
                    .to($marquee.get(0), {
                        opacity: 0,
                        duration: 0.5,
                        onComplete() {
                            $marquee.html($nextMarquee.html());
                        }
                    }, 0)
                    .add(() => {
                        Components.init(el.firstElementChild);
                        setTimeout(() => {
                            const $newGameScreen = $html.find('[data-message]').eq(0);
                            $el.data('message', $newGameScreen.data('message'));
                            Dispatch.emit(REFRESH_CHAT_MESSAGES);
                        }, 1000);
                    })
                    .to([$marquee.get(0), $questionContent.get(0)], {
                        opacity: 1,
                        duration: 0.5,
                        stagger: 0.15
                    });

            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                request = null;
            });
    };

    const finishQuiz = () => {

        // Get the response to show, and update the modal
        const endpoint = `/${Config.get('actionTrigger')}/banorama/quiz/get-result-modal-html`;
        const siteId = $el.find('[data-question] form input[name="siteId"]').val();
        request = superagent.get(endpoint);
        request
            .accept('application/json')
            .query({ siteId })
            .then(({ status, body }) => {
                if (status !== 200 || !body.html) {
                    throw new Error();
                }
                const responseModalHtml = $(`<div>${body.html}</div>`).find('#quiz-response-modal').html();
                responseModal.html(responseModalHtml);
                responseModal.show();
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                request = null;
            });
    };

    const onQuizQuestionCompleted = (key, data) => {
        const { result } = data || {};
        if (request) {
            return;
        }
        if (result === 'complete') {
            playSound(window.QuizSounds.correctAnswer);
        }
        const form = $el.find('[data-question] form').get(0);
        $(form).find('input[type="hidden"][name="result"]').val(result);
        // Post the response to store it in session, then show the response modal
        request = superagent.post(window.location.href);
        request
            .accept('application/json')
            .send(serialize(form))
            .then(({ status, body }) => {
                console.log({ status, body });
                if (status !== 200 || !body.success) {
                    throw new Error();
                }
                Dispatch.emit(QUIZ_PROGRESS_UPDATED);
                showResponseModal();
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                request = null;
            });
    };

    const onQuizQuestionAnswered = (key, data = {}) => {
        const { result } = data;
        setMarquee(result);
        if (result === 'incorrect') {
            playSound(window.QuizSounds.wrongAnswer);
        } else if (result === 'correct') {
            playSound(window.QuizSounds.correctAnswer);
        }
    };

    const init = () => {
        Dispatch.on(QUIZ_QUESTION_COMPLETED, onQuizQuestionCompleted);
        Dispatch.on(QUIZ_QUESTION_ANSWERED, onQuizQuestionAnswered);
        $el.on('click', 'button[data-next-btn]', showNextQuestion);
        $el.on('click', 'button[data-finish-btn]', finishQuiz);
        // Create the response modal
        const responseModalEl = $el.find('#quiz-response-modal').get(0);
        responseModal = new Modal(responseModalEl, {
            escToClose: false
        });
        // Play background music
        bgMusicSound = playSound(window.QuizSounds.loop, { loop: true, volume: 0.5 });
    };

    const destroy = () => {
        Dispatch.off(QUIZ_QUESTION_COMPLETED, onQuizQuestionCompleted);
        Dispatch.off(QUIZ_QUESTION_ANSWERED, onQuizQuestionAnswered);
        $el.off('click');
        if (bgMusicSound) {
            bgMusicSound.stop();
        }
    };

    return {
        init,
        destroy
    };

};
