import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import Viewport from '@vaersaagod/tools/Viewport';
import gsap from 'gsap';

import { START_PANEL_PRELOAD_COMPLETE } from '../lib/events';

export default el => {

    const menu = $('[data-component="frontpage/Menu"]').get(0);

    const onPreloadComplete = () => {
        if (menu) {
            gsap.timeline({
                delay: 1.5
            })
                .fromTo(menu, { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0)
                .fromTo(menu, { yPercent: 100 }, { yPercent: 0, duration: 0.5, ease: 'Back.easeOut' }, 0)
                .set(menu, { clearProps: 'all' });
        }
    };

    const init = () => {
        if (window.history && 'scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
        if (menu) {
            gsap.set(menu, { opacity: 0 });
        }
        Dispatch.on(START_PANEL_PRELOAD_COMPLETE, onPreloadComplete, true);
    };

    const destroy = () => {
        Dispatch.off(START_PANEL_PRELOAD_COMPLETE, onPreloadComplete, true);
    };

    return {
        init,
        destroy
    };

};
