import $ from '@vaersaagod/tools/Dom';

import Parallax from 'parallax-js';

export default el => {

    let parallax = new Parallax(el);

    return {
        destroy() {
            parallax.destroy();
            parallax = null;
        }
    };

};
