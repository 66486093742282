import $ from '@vaersaagod/tools/Dom';
import VideosPanel from '../lib/VideosPanel';

export default el => {

    let modal;

    $(el).on('click', e => {
        e.preventDefault();
        if (!modal) {
            modal = new VideosPanel();
            modal.show();
        } else {
            modal.show();
        }
    });

    return {
        destroy() {
            $(el).off('click mouseenter');
        }
    };

};
