/*
*   ~ Project event keys go in here ~
*
*   Usage in components:
*
*   import Events from '@vaersaagod/tools/Events';
*   import * as eventKeys from '../lib/events';
*   Events.publish(eventKeys.SOME_EVENT, 'This ES6 shit is wack');
*
 */

export const COMPONENT_INIT = 'COMPONENT_INIT';
export const VIEWPORT_HEIGHT_CHANGE = 'VIEWPORT_HEIGHT_CHANGE';

export const PROGRAMMATIC_SCROLL_START = 'PROGRAMMATIC_SCROLL_START';
export const PROGRAMMATIC_SCROLL_END = 'PROGRAMMATIC_SCROLL_END';

export const QUIZ_QUESTION_ANSWERED = 'QUIZ_QUESTION_ANSWERED';
export const QUIZ_QUESTION_COMPLETED = 'QUIZ_QUESTION_COMPLETED';
export const QUIZ_PROGRESS_UPDATED = 'QUIZ_PROGRESS_UPDATED';

export const SOUND_MUTED_CHANGE = 'SOUND_MUTED_CHANGE';

export const REFRESH_CHAT_MESSAGES = 'REFRESH_CHAT_MESSAGES';

export const START_PANEL_PRELOAD_COMPLETE = 'START_PANEL_PRELOAD_COMPLETE';
export const START_PANEL_INTRO_COMPLETE = 'START_PANEL_INTRO_COMPLETE';
