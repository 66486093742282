import $ from '@vaersaagod/tools/Dom';
import superagent from '@vaersaagod/tools/request';
import Components from '@vaersaagod/tools/Components';
import Config from '@vaersaagod/tools/Config';

export default el => {

    const $el = $(el);

    const { actionTrigger } = Config.get();

    const init = () => {
        $el.find('[data-video]').each(video => {
            const videoId = $(video).data('video');
            superagent
                .get(`/${actionTrigger}/banorama/default/get-video-embed-html`)
                .accept('json')
                .query({ videoId })
                .then(({ status, body }) => {
                    if (status !== 200 || !body.html) {
                        throw new Error();
                    }
                    $(video).html(body.html);
                    Components.init(video);
                })
                .catch(error => {
                    console.error(error);
                });
        });
    };

    const destroy = () => {
    };

    return {
        init,
        destroy
    };

};
