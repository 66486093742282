import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import gsap from 'gsap';
import { loadLottie } from '../lib/async-bundles';
import { dispatchEvent } from '../lib/helpers';

import { playSound } from '../lib/Sound';

require('intersection-observer');

export default (el, props) => {

    const { path, options, sound: soundOptions, useSubFrame = false } = props;

    if (!path) {
        return null;
    }

    const lottieOptions = {
        path,
        container: el,
        renderer: 'canvas',
        loop: true,
        autoplay: true,
        scaleMode: 'noScale',
        ...(options || {})
    };

    const introTween = gsap.timeline({ paused: true }).fromTo(el, { opacity: 0 }, { opacity: 1, duration: 0.3, ease: 'Quad.easeOut' });

    let observer;
    let anim;
    let hasInited = false;
    let isLoaded = false;
    let isVisible = false;

    let sound;
    if (soundOptions) {
        sound = playSound(soundOptions.url, {
            autoplay: false,
            ...(soundOptions.options || {})
        });
    }

    const onResize = () => {
        if (!isLoaded || !isVisible) {
            return;
        }
        if (lottieOptions.renderer === 'canvas') {
            anim.resize();
        }
    };

    const onEnterFrame = () => {
        if (!sound) {
            anim.removeEventListener('enterFrame', onEnterFrame);
            return;
        }
        const { frame = 1 } = soundOptions;
        if (anim.currentFrame >= frame) {
            anim.removeEventListener('enterFrame', onEnterFrame);
            sound.play();
        }
    };

    const onLoaded = () => {
        isLoaded = true;
        anim.removeEventListener('data_ready', onLoaded);
        requestAnimationFrame(() => {
            introTween.play();
            dispatchEvent(el, 'lottie_ready', { detail: { lottie: anim } });
            onResize();
        });
        if (!isVisible) {
            anim.pause();
        }
    };

    const initLottie = () => {
        if (hasInited) return;
        hasInited = true;
        loadLottie(Lottie => {
            Lottie.setQuality('low');
            anim = Lottie.loadAnimation(lottieOptions);
            anim.setSubframe(useSubFrame);
            anim.addEventListener('data_ready', onLoaded);
            if (sound) {
                // Use enter frame handler to play/sync sound
                anim.addEventListener('enterFrame', onEnterFrame);
            }
        });
    };

    const init = () => {
        observer = new IntersectionObserver(entries => {
            const { isIntersecting } = entries[0];
            isVisible = isIntersecting;
            if (isIntersecting) {
                el.hidden = false;
                if (!hasInited) {
                    initLottie();
                    return;
                }
                onResize();
                if (isLoaded && lottieOptions.autoplay) {
                    anim.play();
                }
            } else {
                el.hidden = true;
                if (isLoaded && lottieOptions.autoplay) {
                    anim.pause();
                }
            }
        }, {
            //treshold: 0,
            rootMargin: '200px 0px 1000px 0px'
        });
        Viewport.on('resize', onResize);
        requestAnimationFrame(() => {
            observer.observe(el.parentElement);
        });
    };

    const destroy = () => {
        if (anim) {
            anim.destroy();
        }
        observer.disconnect();
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };

};
