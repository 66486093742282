import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';

export default el => {

    const lottie = $(el).find('[data-lottie]').get(0);

    let animation;
    let startY;
    let endY;
    let doPlay;

    const play = () => {
        if (!animation) return;
        animation.play();
        console.log('play!');
    };

    const pause = () => {
        if (!animation) return;
        animation.goToAndStop(9, true);
    };

    const onScroll = () => {
        const { top } = el.getBoundingClientRect();
        doPlay = top < startY && top > endY;
        if (doPlay) {
            play();
        } else {
            pause();
        }
    };

    const onResize = () => {
        const { height } = el.getBoundingClientRect();
        startY = ((Viewport.height - height) * 0.5) + (height * 0.15);
        endY = -height;
        onScroll();
    };

    const onLottieReady = e => {
        animation = e.detail.lottie;
        if (doPlay) {
            play();
        } else {
            pause();
        }
    };

    const init = () => {
        lottie.addEventListener('lottie_ready', onLottieReady);
        Viewport.on('scroll', onScroll);
        Viewport.on('resize', onResize);
        onResize();
    };

    const destroy = () => {
        lottie.removeEventListener('lottie_ready', onLottieReady);
        Viewport.off('scroll', onScroll);
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };

};
