import $ from '@vaersaagod/tools/Dom';
import gsap from 'gsap';

export default el => {

    const $el = $(el);
    const $embed = $el.find('[data-embed]');
    const $btn = $el.find('[data-btn]');
    const $poster = $el.find('[data-poster]');

    let hasClicked = false;

    const onClick = e => {
        e.preventDefault();
        if (hasClicked) {
            return;
        }
        hasClicked = true;
        const embed = $embed.data('embed');
        $embed.html(embed);
        $embed.get(0).hidden = false;
        gsap.timeline({
            onComplete() {
                $btn.get(0).hidden = true;
            }
        })
            .fromTo($embed.get(0), { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0)
            .to($poster.get(0), { opacity: 0, duration: 0.3 }, 0);
    };

    const init = () => {
        $btn.on('click', onClick);
    };

    const destroy = () => {
        $btn.off('click');
    };

    return {
        init,
        destroy
    };

};
