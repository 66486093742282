import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Dispatch from '@vaersaagod/tools/Dispatch';

import ScrollTrigger from 'gsap/ScrollTrigger';
import gsap from 'gsap';

import { loadLottie } from '../lib/async-bundles';

import { REFRESH_CHAT_MESSAGES, START_PANEL_INTRO_COMPLETE } from '../lib/events';

const Set = require('es6-set');

gsap.registerPlugin(ScrollTrigger);
gsap.core.globals('ScrollTrigger', ScrollTrigger);

export default (el, { dotsUrl }) => {

    const messagesDiv = el.firstElementChild;

    const hasPreloader = !!$('#loader').get(0);

    let triggers = [];
    let messages = {};
    let queuedMessages = new Set();

    let isScrolling = false;
    let scrollTimer = null;

    const isSmall = () => ['m', 'mp', 'l', 'lp', 'xl'].indexOf(Viewport.breakpoint.name) === -1;

    const closeMessage = message => {
        const textWrap = $(message).find('[data-text-wrap]').get(0);
        gsap.timeline()
            .to(textWrap, { opacity: 0, duration: 0.3 }, 0.1)
            .to(textWrap, {
                y: 5,
                duration: 0.3,
                ease: 'Back.easeIn',
                transformOrigin: 'right bottom'
            }, 0)
            .add(() => {
                textWrap.hidden = true;
            });
        $(message).removeClass('is-open');
    };

    const openMessage = (message, showDots = false) => {
        const $message = $(message);
        const textWrap = $(message).find('[data-text-wrap]').get(0);
        const notification = $(message).find('[data-notification]').get(0);
        textWrap.hidden = false;
        const tl = gsap.timeline()
            .set(textWrap, { y: 0 })
            .fromTo(textWrap, { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0)
            .fromTo(textWrap, { rotation: -45 }, {
                rotation: 0,
                duration: 0.5,
                ease: 'Back.easeOut',
                transformOrigin: 'right bottom'
            }, 0);
        if (notification.offsetParent !== null) {
            tl
                .to(notification, { scale: 0.3, y: 3, duration: 0.3, ease: 'Back.easeIn' }, 0)
                .to(notification, { opacity: 0, duration: 0.3, ease: 'Cubic.easeIn' }, 0);
        }
        if (showDots) {
            const dots = $message.find('[data-dots]').get(0);
            const text = $message.find('[data-text]').get(0);
            const uid = $message.attr('data-uid');
            dots.hidden = false;
            gsap.set(text, { opacity: 0, position: 'absolute' });
            loadLottie(Lottie => {
                if (document.body.contains(dots) && !!messages[uid]) {
                    Lottie.setQuality('low');
                    const dotsAnim = Lottie.loadAnimation({
                        path: dotsUrl,
                        container: dots,
                        renderer: 'svg',
                        loop: true,
                        autoplay: true
                    });
                    $(message).data('_dotsAnim', dotsAnim);
                }
            });
            gsap.timeline({ delay: 1.5 })
                .to(dots, {
                    opacity: 0,
                    duration: 0.3,
                    onComplete() {
                        dots.hidden = true;
                    }
                }, 0)
                .add(() => {
                    if (!document.body.contains($message.get(0)) || !messages[uid]) {
                        return;
                    }
                    const { width, height } = textWrap.getBoundingClientRect();
                    gsap.set(text, { position: 'relative' });
                    const { width: textWidth, height: textHeight } = text.getBoundingClientRect();
                    const { width: newWidth, height: newHeight } = textWrap.getBoundingClientRect();
                    gsap.set(text, {
                        width: textWidth,
                        height: textHeight,
                        position: 'absolute',
                        top: 0,
                        left: 0
                    });
                    const dotsTl = gsap.timeline({
                        paused: true
                    })
                        .fromTo(textWrap, { width, height }, {
                            width: newWidth,
                            height: newHeight,
                            duration: 0.5,
                            ease: 'Power2.easeOut',
                            transformOrigin: 'right top'
                        }, 0)
                        .to(text, { opacity: 1, duration: 0.3, ease: 'Sine.easeIn' }, 0.3)
                        .set([textWrap, text], { clearProps: 'all' });
                    requestAnimationFrame(() => {
                        dotsTl.play();
                    });
                });
        }
        $(message).addClass('is-open');
    };

    const onMessageClick = e => {
        const { target } = e;
        const message = $(target).parent('[data-message]').get(0);
        if ($(message).hasClass('is-open')) {
            closeMessage(message);
        } else {
            openMessage(message);
        }
    };

    const updateMessages = () => {
        const visibleMessageDivs = $(messagesDiv).find('[data-message]:not([hidden])').get().reverse();
        const tl = gsap.timeline({ paused: true });
        let offset = 0;
        visibleMessageDivs.forEach(message => {
            tl.to(message, { y: -offset, duration: 0.5 }, 0);
            offset += $(message).height() + 20;
        });
        tl.play();
    };

    const showMessage = message => {

        const { uid } = message;

        if (messages[uid]) {
            return;
        }

        queuedMessages.delete(message);

        // Create message
        const $message = $(messagesDiv).find('[data-message][hidden]').eq(0).clone();
        const $text = $message.find('[data-text]');
        $text.html(message.text).get(0);
        messages[uid] = $message.get(0);

        $message.attr('data-uid', uid);

        $(messagesDiv).append($message);
        $message.get(0).hidden = false;

        const textWrap = $message.find('[data-text-wrap]').get(0);
        textWrap.hidden = true;

        const avatar = $message.find('[data-avatar]').parent().get(0);
        const messageTl = gsap.timeline({
            paused: true,
            delay: 0.1
        })
            .fromTo($message.get(0), { yPercent: 100 }, {
                yPercent: 0,
                duration: 0.5,
                ease: 'Quint.easeOut'
            }, 0)
            .fromTo($message.get(0), { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0)
            .fromTo(avatar, { opacity: 0 }, {
                opacity: 1,
                duration: 0.3
            }, 0)
            .add(() => {
                if (!isSmall()) {
                    openMessage($message, message.showDots);
                }
            }, 0);

        $message.find('[data-avatar] img').on('load', () => {
            messageTl.play();
            updateMessages();
        });

        $message.find('[data-avatar] img').eq(0).attr({ src: message.avatar });
        $message.find('button').on('click', onMessageClick);
    };

    const removeMessageDiv = (div, delay = 0) => {
        gsap.timeline({
            delay,
            onComplete: () => {
                const $message = $(div);
                if ($message.data('_dotsAnim')) {
                    $message.data('_dotsAnim').destroy();
                }
                $message.find('button').off('click');
                $message.remove();
            }
        })
            .to(div, {
                opacity: 0,
                duration: 0.3
            }, 0)
            .to(div.firstElementChild, {
                yPercent: -200,
                duration: 0.5,
                ease: 'Cubic.easeIn'
            }, 0);
    };

    const removeMessage = message => {
        const { uid } = message;
        const div = messages[uid] || null;
        if (!div) {
            return;
        }
        messages[uid] = null;
        removeMessageDiv(div, message.removeDelay);
    };

    const destroyScrollTriggers = () => {
        triggers.forEach(trigger => trigger.kill());
        triggers = [];
    };

    const createScrollTriggers = () => {
        destroyScrollTriggers();
        const anchors = $('[data-message]').get();
        if (!anchors.length) {
            return;
        }
        messages = {};
        anchors.forEach(anchor => {
            const message = $(anchor).data('message');
            if (!message.text) {
                return;
            }
            const { offsetTop, offsetBottom, persist } = message;
            let start;
            if (offsetTop) {
                start = `top+=${offsetTop} bottom`;
            } else {
                start = 'top bottom';
            }
            let end;
            if (persist) {
                end = null;
            } else if (offsetBottom) {
                end = `bottom+=${offsetBottom} bottom`;
            } else {
                end = 'bottom+=20px bottom';
            }
            const trigger = ScrollTrigger.create({
                trigger: anchor,
                start,
                end,
                //markers: true,
                onEnter() {
                    if (!queuedMessages.has(message)) {
                        queuedMessages.add(message);
                    }
                    if (!isScrolling) {
                        showMessage(message);
                    }
                },
                onEnterBack() {
                    if (!queuedMessages.has(message)) {
                        queuedMessages.add(message);
                    }
                    if (!isScrolling) {
                        showMessage(message);
                    }
                },
                onLeave() {
                    queuedMessages.delete(message);
                    removeMessage(message);
                },
                onLeaveBack() {
                    queuedMessages.delete(message);
                    removeMessage(message);
                }
            });
            triggers.push(trigger);
        });
    };

    const onRefreshChatMessages = () => {
        // Remove all existing messages
        queuedMessages = new Set();
        Object.values(messages).forEach(div => {
            removeMessageDiv(div);
        });
        createScrollTriggers();
    };

    const onScroll = () => {
        if (scrollTimer) {
            clearTimeout(scrollTimer);
        }
        isScrolling = true;
        scrollTimer = setTimeout(() => {
            isScrolling = false;
            const message = Array.from(queuedMessages).pop();
            if (message) {
                showMessage(message);
            }
        }, 100);
    };

    const init = () => {
        Viewport.on('scroll', onScroll);
        Dispatch.on(REFRESH_CHAT_MESSAGES, onRefreshChatMessages);
        if (hasPreloader) {
            Dispatch.on(START_PANEL_INTRO_COMPLETE, createScrollTriggers, true);
            return;
        }
        // On pageload, wait a second or two
        setTimeout(() => {
            createScrollTriggers();
        }, 1000);
    };

    const destroy = () => {
        destroyScrollTriggers();
        Viewport.off('scroll', onScroll);
        Dispatch.off(REFRESH_CHAT_MESSAGES, onRefreshChatMessages);
        Dispatch.off(START_PANEL_INTRO_COMPLETE, createScrollTriggers);
    };

    return {
        init,
        destroy
    };

};
