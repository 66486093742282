import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Modal from '../lib/ModalCircle';

export default el => {

    const $el = $(el);
    const modalEl = $(`#${el.dataset.modalId}`).get(0);
    const label = $el.find('[data-label]').get(0);

    let modal;

    const onClick = () => {
        if (!modal) {
            return;
        }
        modal.show();
    };

    const onResize = () => {
        if (!label) {
            return;
        }
        $(label).css({
            width: '',
            maxWidth: '',
            whiteSpace: 'nowrap',
            overflow: ''
        });
        let width = $(label).width();
        $(label).width(width);
        const { left: buttonLeft } = $(el).offset();
        const { left } = $(label).offset();
        const position = left > buttonLeft ? 'right' : 'left';
        const { width: viewportWidth } = Viewport;
        let over;
        if (position === 'right') {
            over = (left + width) - (viewportWidth - 20);
            if (over <= 0) {
                return;
            }
            width = Math.floor(width - over);
        } else {
            over = left - 20;
            if (over >= 0) {
                return;
            }
            width = Math.floor(width + over);
        }
        $(label).css({
            width: '',
            maxWidth: width,
            whiteSpace: 'normal',
            overflow: 'hidden'
        });
    };

    const init = () => {
        if (modalEl) {
            modal = new Modal(modalEl);
        }
        $el.on('click', onClick);
        Viewport.on('resize', onResize);
        onResize();
    };

    const destroy = () => {
        if (modal) {
            modal.destroy();
            modal = null;
        }
        $el.off('click');
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };

};
