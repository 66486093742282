import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import Config from '@vaersaagod/tools/Config';
import superagent from '@vaersaagod/tools/request';
import { QUIZ_PROGRESS_UPDATED } from '../../lib/events';

export default el => {

    const $el = $(el);

    const onProgressUpdated = () => {
        const endpoint = `/${Config.get('actionTrigger')}/banorama/quiz/get-progress-bar-html`;
        superagent
            .get(endpoint)
            .accept('application/json')
            .then(({ status, body }) => {
                if (status !== 200 || !body.html) {
                    throw new Error();
                }
                $el.html($(body.html).html());
            })
            .catch(error => {
                console.error(error);
            });
    };

    const init = () => {
        Dispatch.on(QUIZ_PROGRESS_UPDATED, onProgressUpdated);
    };

    const destroy = () => {
        Dispatch.off(QUIZ_PROGRESS_UPDATED, onProgressUpdated);
    };

    return {
        init,
        destroy
    };

};
