import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import gsap from 'gsap';
import Dispatch from '@vaersaagod/tools/Dispatch';
import {
    COMPONENT_INIT,
    START_PANEL_INTRO_COMPLETE,
    START_PANEL_PRELOAD_COMPLETE
} from '../../lib/events';
import { Preloader } from '../../lib/Preloader';

export default el => {

    const $el = $(el);

    let logoReady = false;
    let logoFailed = false;
    let stuffIsPreloaded = false;
    let isTabbingLocked = false;
    let preloader = false;

    const $html = $('html');
    const isOldBrowser = $html.hasClass('ie') || $html.hasClass('edge');

    const loaderDiv = $('#loader').get(0);

    const matterLogo = $el.find('[data-matterlogo-canvas]').get(0);
    const svgLogoWrapper = $el.find('[data-logo-wrapper]').get(0);
    const people = $el.find('[data-peoples]').get(0);
    const videoBtn = $el.find('[data-videobtn]').get(0);
    const garbageCan = $el.find('[data-garbage]').get(0);
    const clock = $el.find('[data-clock]').get(0);
    const sign = $el.find('[data-sign]').get(0);

    const getLogoSvg = () => $el.find('[data-logo]').get().filter(node => $(node).css('display') !== 'none')[0].querySelector('svg');

    const playIntro = () => {
        // People timeline
        const peopleTimeline = gsap.timeline()
            .set(people, { clearProps: 'opacity' })
            .fromTo(people.children, {
                scaleY: 0.3,
                yPercent: 20,
                transformOrigin: 'center bottom'
            }, {
                scaleY: 1,
                yPercent: 0,
                transformOrigin: 'center bottom',
                duration: 0.5,
                ease: 'Back.easeOut',
                stagger: 0.1
            }, 'people')
            .fromTo(people.children, { opacity: 0 }, {
                opacity: 1,
                duration: 0.3,
                ease: 'Sine.easeIn',
                stagger: 0.1
            }, 'people');
        // Logo timeline
        const $logo = $(getLogoSvg());
        const logoIsStacked = $logo.parent('[data-logo]').data('logo') === 'stacked';
        const logoLetters = $logo.find('g').get();
        // Which logo is it?
        let firstLogoLetters;
        let lastLogoLetters;
        const logoTimeline = gsap.timeline().set(svgLogoWrapper, { opacity: 1 });
        if (logoIsStacked) {
            firstLogoLetters = logoLetters.slice(0, 4);
            lastLogoLetters = logoLetters.slice(4, 8);
            [firstLogoLetters, lastLogoLetters].forEach((letters, index) => {
                logoTimeline
                    .fromTo(letters, {
                        scaleY: 0.3,
                        yPercent: 80,
                        transformOrigin: 'center bottom'
                    }, {
                        scaleY: 1,
                        yPercent: 0,
                        transformOrigin: 'center bottom',
                        duration: 0.75,
                        stagger: 0.05,
                        ease: 'Back.easeOut'
                    }, `logo+=${0.1 * index}`)
                    .fromTo(letters, { opacity: 0 }, {
                        opacity: 1,
                        duration: 0.1,
                        stagger: 0.05
                    }, `logo+=${0.15 * index}`);
            });
        } else {
            firstLogoLetters = logoLetters.slice(0, 4).reverse();
            lastLogoLetters = logoLetters.slice(4, 8);
            [firstLogoLetters, lastLogoLetters].forEach(letters => {
                logoTimeline
                    .fromTo(letters, {
                        scaleY: 0.35,
                        yPercent: 60,
                        transformOrigin: 'center bottom'
                    }, {
                        scaleY: 1,
                        yPercent: 0,
                        transformOrigin: 'center bottom',
                        duration: 0.75,
                        ease: 'Back.easeOut',
                        stagger: 0.05
                    }, 'logo')
                    .fromTo(letters, { opacity: 0 }, {
                        opacity: 1,
                        duration: 0.1,
                        stagger: 0.05
                    }, 'logo');
            });
        }
        const garbageTl = gsap.timeline().fromTo(garbageCan, { yPercent: -100 }, { yPercent: 0, duration: 0.75, ease: 'Back.easeOut' });
        const clockTl = gsap.timeline().fromTo(clock, { yPercent: -100 }, { yPercent: 0, duration: 0.75, ease: 'Back.easeOut' });
        const videoBtnTl = gsap.timeline().fromTo(videoBtn, { scale: 0 }, { scale: 1, duration: 0.3, ease: 'Back.easeOut' }, 0);
        const signTl = gsap.timeline().fromTo(sign, { rotate: -180, transformOrigin: 'left bottom' }, { rotate: 0, transformOrigin: 'left bottom', duration: 1.5, ease: 'Back.easeOut' });
        const tl = gsap.timeline({
            paused: true,
            onComplete: () => {
                Dispatch.emit(START_PANEL_INTRO_COMPLETE);
            }
        })
            .to(loaderDiv, {
                opacity: 0,
                duration: 0.5,
                onComplete: () => {
                    Viewport.releaseTabbing();
                    isTabbingLocked = false;
                    preloader.hide();
                }
            }, 'people')
            .to(loaderDiv.children, { scale: 0.5, duration: 0.75, ease: 'Back.easeIn' }, 'people')
            .set([garbageCan, clock, videoBtn, sign], { opacity: 1 })
            .add(peopleTimeline, 'people')
            .add(logoTimeline, 'logo-=0.2');
        if (logoReady && !logoFailed) {
            tl
                .to(svgLogoWrapper, { opacity: 0, duration: 0.3, ease: 'none' }, 'canvas')
                .set(matterLogo, { opacity: 1 }, 'canvas');
        }
        tl
            .add(clockTl, 'stuff-=0.75')
            .add(garbageTl, 'stuff-=0.6')
            .add(signTl, 'stuff-=0.4')
            .add(videoBtnTl, 'stuff-=0.3');
        requestAnimationFrame(() => {
            tl.play();
        });
    };

    const maybePlayIntro = () => {
        if (!logoReady || !stuffIsPreloaded) {
            return;
        }
        playIntro();
    };

    const onPreloaded = () => {
        Dispatch.emit(START_PANEL_PRELOAD_COMPLETE);
        stuffIsPreloaded = true;
        maybePlayIntro();
    };

    const init = () => {

        window.scrollTo(0, 0);

        // Create loader
        preloader = new Preloader(loaderDiv, {
            onComplete: onPreloaded
        });

        if (!isOldBrowser) {
            // Wait for the logo to be ready
            $(svgLogoWrapper).parent('[data-component]').on('matter_logo_ready', () => {
                logoReady = true;
                gsap.set(matterLogo, { opacity: 0 });
                maybePlayIntro();
            });
            $(svgLogoWrapper).parent('[data-component]').on('matter_logo_failed', () => {
                logoReady = true;
                logoFailed = true;
                matterLogo.hidden = true;
                maybePlayIntro();
            });
        } else {
            logoReady = true;
            logoFailed = true;
            matterLogo.hidden = true;
            maybePlayIntro();
        }

        // Preload all the things
        preloader.loadLotties($el.find('[data-lottie]').get());
        preloader.loadImages($el.find('img.lazyload:not(.lazyloaded)').get());
        preloader.show();

        requestAnimationFrame(() => {
            isTabbingLocked = true;
            Viewport.lockTabbing(loaderDiv);
            window.scrollTo(0, 0);
        });

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        $(svgLogoWrapper).parent('[data-component]').off('matter_logo_ready');
        $(svgLogoWrapper).parent('[data-component]').off('matter_logo_failed');
        if (isTabbingLocked) {
            Viewport.releaseTabbing();
        }
        preloader.destroy();
    };

    return {
        init,
        destroy
    };

};
