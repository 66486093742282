import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';

export default el => {

    const $el = $(el);
    const $text = $el.find('[data-text]');
    const $track = $el.find('[data-track]');

    const onResize = () => {
        if ($text.css('position') === 'absolute') {
            $text.height($track.height());
        } else {
            $text.height('');
        }
    };

    const init = () => {
        Viewport.on('resize', onResize);
        onResize();
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };

};
