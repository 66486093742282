import { Howl } from 'howler';
import Config from '@vaersaagod/tools/Config';
import Dispatch from '@vaersaagod/tools/Dispatch';

import { SOUND_MUTED_CHANGE } from './events';

let isSoundMuted = false;

const sounds = [];

export const playSound = (url, options = {}) => {

    if (Config.get('isPreview') || (isSoundMuted && !options.loop)) {
        return null;
    }

    const sound = new Howl({
        src: [url],
        autoplay: true,
        mute: isSoundMuted,
        ...(options || {})
    });

    sounds.push(sound);

    return sound;
};

export const mute = () => {
    sounds.forEach(sound => {
        sound.mute(true);
    });
};

export const unmute = () => {
    sounds.forEach(sound => {
        sound.mute(false);
    });
};

const onMuteChange = (key, { isMuted }) => {
    isSoundMuted = isMuted;
    if (isSoundMuted) {
        mute();
    } else {
        unmute();
    }
};

Dispatch.on(SOUND_MUTED_CHANGE, onMuteChange, true);
