import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';

export default el => {

    const $el = $(el);

    const onResize = () => {
        $el.height(Math.ceil(el.firstElementChild.getBoundingClientRect().height));
    };

    const init = () => {
        Viewport.on('resize', onResize);
        onResize();
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };

};
