import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Dispatch from '@vaersaagod/tools/Dispatch';
import gsap from 'gsap';

import { PROGRAMMATIC_SCROLL_START, PROGRAMMATIC_SCROLL_END } from './events';

/**
 *
 * @param node
 * @param eventName
 * @param eventOpts
 */
export const dispatchEvent = (node, eventName, eventOpts) => {
    node.dispatchEvent(new CustomEvent(eventName, {
        bubbles: true,
        cancelable: true,
        ...(eventOpts || {})
    }));
};

/**
 *
 * @param number
 * @param min
 * @param max
 * @returns {number}
 */
export const clamp = (number, min, max) => Math.max(min, Math.min(number, max));

/**
 *
 * @param node
 * @param opts
 */
export const scrollTo = (node, opts = {}) => {

    const scrollTop = $(node).offset().top;
    const direction = Viewport.scrollTop < scrollTop ? 'down' : 'up';

    Dispatch.emit(PROGRAMMATIC_SCROLL_START, {
        target: node,
        direction
    });

    const pixelsPerSecond = 1500;
    const dist = Math.abs(Viewport.scrollTop - scrollTop);
    const baseDuration = dist / pixelsPerSecond;
    const duration = clamp(baseDuration, 0.5, 2);

    requestAnimationFrame(() => {
        gsap.to($('html').get(0), {
            duration,
            scrollTop,
            ease: 'Quad.easeInOut',
            onComplete() {
                Dispatch.emit(PROGRAMMATIC_SCROLL_END);
            },
            ...opts
        });
    });

};

/**
 *
 * @param rgb
 * @returns {string}
 */
export const rgb2hex = rgb => `#${rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/).slice(1).map(n => parseInt(n, 10).toString(16).padStart(2, '0')).join('')}`;

/**
 *
 * @returns {number}
 */
export const getDocHeight = () => Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
