import $ from '@vaersaagod/tools/Dom';
import gsap from 'gsap';

require('intersection-observer');

export default el => {

    const $el = $(el);

    const lottie = $el.find('[data-lottie]').get(0);

    let observer;
    let animation;
    let tl;

    const onObserve = entries => {
        const { isIntersecting } = entries[0];
        console.log({ isIntersecting });
        if (!isIntersecting) {
            tl.pause(0, false);
        } else {
            tl.play();
        }
    };

    const onLottieReady = e => {
        animation = e.detail.lottie;
        const animFrames = { frame: 1 };
        tl = gsap.timeline({ paused: true })
            .fromTo(animFrames, { frame: 1 }, {
                frame: 140,
                ease: 'none',
                duration: 2.5,
                onUpdate() {
                    animation.goToAndStop(animFrames.frame, true);
                }
            }, 0)
            .fromTo(lottie, { xPercent: -100 }, {
                xPercent: 0,
                duration: 3,
                ease: 'Power4.easeOut'
            }, 0)
            .add(() => {
                animation.goToAndStop(180, true);
                animation.play();
            });
        observer = new IntersectionObserver(onObserve);
        observer.observe(lottie.parentNode);
    };

    const init = () => {
        lottie.addEventListener('lottie_ready', onLottieReady);
    };

    const destroy = () => {
        if (observer) {
            observer.disconnect();
        }
        lottie.removeEventListener('lottie_ready', onLottieReady);
    };

    return {
        init,
        destroy
    };

};
