import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import { SOUND_MUTED_CHANGE } from '../../lib/events';

export default (el, { isSoundMuted: wasMuted, soundMutedCookieName: cookieName }) => {

    const $el = $(el);

    let isMuted = wasMuted;

    const onMutedChanged = (key, { isMuted: hasBeenMuted }) => {
        if (hasBeenMuted) {
            $el.addClass('is-muted');
        } else {
            $el.removeClass('is-muted');
        }
    };

    const onClick = () => {
        isMuted = !isMuted;
        Dispatch.emit(SOUND_MUTED_CHANGE, { isMuted });
        window.vrsg.setCookie(cookieName, isMuted ? '1' : '0');
    };

    const init = () => {
        $el.on('click', onClick);
        Dispatch.on(SOUND_MUTED_CHANGE, onMutedChanged, true);
        Dispatch.emit(SOUND_MUTED_CHANGE, { isMuted });
    };

    const destroy = () => {
        $el.off('click', onClick);
        Dispatch.off(SOUND_MUTED_CHANGE, onMutedChanged);
    };

    return {
        init,
        destroy
    };

};
